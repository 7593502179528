import React from 'react';
import './App.css';
import CustomizerProvider from './helper/customizer/CustomizerProvider';
import AnimationThemeProvider from './helper/AnimationTheme/AnimationThemeProvider';
import Routers from './route';

const App: React.FC = (): JSX.Element => (
  <CustomizerProvider>
    <AnimationThemeProvider>
      <Routers />
    </AnimationThemeProvider>
  </CustomizerProvider>
);

export default App;
