import { Validator } from '@/hooks/useForm';
import { parsePhone } from './phone';

const isValidString = (value: string): boolean => {
  const newValue = value.replace(/[^0-9a-zA-Z]/g, '');

  return newValue.length > 0;
};

export const required: Validator = value => {
  const valid = isValidString(value);
  return valid ? false : 'Campo deve ser preenchido';
};

export const hasPasswordOnlyNumberCharacteres: Validator = value => {
  const rawValue = value.replace(/[^0-9a-zA-Z]/g, '');
  const onlyMixOfAlphaNumeric = /^([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*$/;

  if (onlyMixOfAlphaNumeric.test(rawValue)) {
    return false;
  }
  return 'Sua senha deve conter ao menos uma letra e um número.';
};

export const phone: Validator = value => (parsePhone(value) ? false : 'Número de celular inválido');
