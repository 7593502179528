import React from 'react';
import { DashboardScreen } from '@/feature/dashboard/screens';
import { CancelItemScreen } from '@/feature/cancelItem/screens';
import { CancelCouponScreen } from '@/feature/cancelCoupon/screens';
import { DiscountScreen } from '@/feature/discount/screens';
import { ReturnCouponScreen } from '@/feature/returnCoupon/screens';
import { BleedingScreen } from '@/feature/bleeding/screens';

export const routes = [
  { path: `/dashboard`, Component: <DashboardScreen /> },
  { path: `/solutions/safe-checkout/cancel-item`, Component: <CancelItemScreen /> },
  { path: `/solutions/safe-checkout/cancel-coupon`, Component: <CancelCouponScreen /> },
  { path: `/solutions/safe-checkout/discount`, Component: <DiscountScreen /> },
  { path: `/solutions/safe-checkout/return-coupon`, Component: <ReturnCouponScreen /> },
  { path: `/solutions/safe-checkout/bleeding`, Component: <BleedingScreen /> },
];
