export interface MenuItemChild {
  path?: string;
  title: string;
  icon?: string;
  type: string;
  active?: boolean;
  children?: MenuItemChild[];
}

export interface MenuItem {
  path?: string;
  title: string;
  icon: string;
  type: string;
  bookmark?: boolean;
  badge?: string;
  badgetxt?: string;
  badge1?: boolean;
  badge2?: boolean;
  active: boolean;
  children?: MenuItemChild[];
  // menutitle?: string;
  // menucontent?: string;
}

export interface Menu {
  menutitle: string;
  menucontent: string;
  Items: MenuItem[];
  active: boolean;
}

export const MENUITEMS: Menu[] = [
  {
    menutitle: 'Geral',
    menucontent: 'Dashboard,Indicadores',
    Items: [
      {
        title: 'Dashboard',
        icon: 'home',
        type: 'link',
        path: '/dashboard',
        active: true,
      },
      /*
      {
        title: 'Indicadores',
        icon: 'bar-chart',
        type: 'link',
        path: '/dashboard',
        active: false,
      },
      */
    ],
    active: true,
  },

  {
    menutitle: 'Soluções',
    menucontent: 'Safe Checkout,Safe Scales,Checklists',
    Items: [
      {
        title: 'Safe Checkout',
        icon: 'safe-receipt',
        type: 'sub',
        // badge: 'badge pill-badge-success',
        // badgetxt: '2',
        active: false,
        children: [
          {
            path: `/solutions/safe-checkout/cancel-item`,
            type: 'link',
            title: 'Itens cancelados',
          },
          {
            path: `/solutions/safe-checkout/cancel-coupon`,
            type: 'link',
            title: 'Cupons cancelados',
          },
          {
            path: `/solutions/safe-checkout/discount`,
            type: 'link',
            title: 'Descontos',
          },
          {
            path: `/solutions/safe-checkout/return-coupon`,
            type: 'link',
            title: 'Devoluções',
          },
          {
            path: `/solutions/safe-checkout/bleeding`,
            type: 'link',
            title: 'Sangrias',
          },
        ],
      },
      /*
      {
        title: 'Safe Scales',
        icon: 'box',
        type: 'link',
        path: `/app/file-manager`,
      },
      {
        title: 'Checklists',
        icon: 'checklist',
        type: 'sub',
        active: false,
      },
      */
    ],
    active: false,
  },
  /*
  {
    menutitle: 'Conhecimento',
    menucontent: 'Soluções,Social App',
    Items: [
      {
        title: 'Soluções',
        icon: 'reading-book',
        type: 'sub',
        menutitle: 'Forms & Table',
        menucontent: 'Ready to use froms & tables',
        active: false,
      },

      {
        title: 'Social App',
        icon: 'flash',
        type: 'link',
      },
    ],
  },

  {
    menutitle: 'Suporte',
    menucontent: 'FAQ',
    Items: [
      {
        title: 'FAQ',
        icon: 'information',
        type: 'sub',
        active: false,
      },

      {
        title: 'Email',
        icon: 'email',
        type: 'link',
        badge1: true,
        active: false,
      },

      {
        title: 'Safe GPT',
        icon: 'machine-learning',
        path: `/icons/flag_icons`,
        type: 'link',
        active: false,
        bookmark: true,
      },

      {
        title: 'Support Ticket',
        icon: 'user-group',
        type: 'sub',
        active: false,
      },
    ],
  },
  */
];
