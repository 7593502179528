/* eslint-disable no-nested-ternary */
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CustomizerContext from '@/helper/customizer';
import { /* Menu, */ Menu, MenuItem /* , MenuItemChild */ } from '../Sidebar/Menu';
import SvgIcon from '../SvgIcon';

interface StateProps {
  menu: Menu;
  sidebartoogle: boolean;
}

interface DispatchProps {
  // setMainMenu: (menus: Menu[]) => void;
  setNavActive: (menu: Menu, item: MenuItem) => void;
  activeClass: (value: boolean | undefined) => void;
}

type Props = StateProps & DispatchProps;

const SidebarMenuItems: React.FC<Props> = ({
  // setMainMenu,
  menu,
  sidebartoogle,
  setNavActive,
  activeClass,
}) => {
  const { layout } = useContext(CustomizerContext);
  const layout1 = localStorage.getItem('sidebar_layout') || layout;

  const id = window.location.pathname.split('/').pop();
  const layoutId = id;
  const CurrentPath = window.location.pathname;

  const { t } = useTranslation();
  /*
  const toggletNavActive = (item: MenuItem | MenuItemChild): void => {
    if (window.innerWidth <= 991) {
      const pageHeader = document.querySelector('.page-header');
      const sidebarWrapper = document.querySelector('.sidebar-wrapper');
      if (pageHeader) {
        pageHeader.className = 'page-header close_icon';
      }
      if (sidebarWrapper) {
        sidebarWrapper.className = 'sidebar-wrapper close_icon ';
      }
      // document.querySelector('.mega-menu-container').classList.remove('d-block');
      if (item.type === 'sub') {
        if (pageHeader) {
          pageHeader.className = 'page-header';
        }
        if (sidebarWrapper) {
          sidebarWrapper.className = 'sidebar-wrapper';
        }
      }
    }
    if (!item.active) {
      MENUITEMS.map(a => {
        a.Items.filter(Items => {
          if (a.Items.includes(item as MenuItem)) {
            // eslint-disable-next-line no-param-reassign
            Items.active = false;
          }
          if (!Items.children) {
            return false;
          }
          Items.children.forEach(b => {
            if (Items.children?.includes(item)) {
              // eslint-disable-next-line no-param-reassign
              b.active = false;
            }
            if (!b.children) {
              return false;
            }
            b.children.forEach(c => {
              if (b.children?.includes(item)) {
                // eslint-disable-next-line no-param-reassign
                c.active = false;
              }
            });
            return true;
          });
          return Items;
        });
        return a;
      });
    }
    // eslint-disable-next-line no-param-reassign
    item.active = !item.active;
    setMainMenu(MENUITEMS);
  };
  */

  return (
    <>
      {menu && (
        <Fragment>
          <li className="sidebar-main-title">
            <div>
              <h6 className="lan-1">{t(menu.menutitle)}</h6>
            </div>
          </li>
          {menu.Items.map((menuItem, index) => (
            <li className="sidebar-list" key={`${index}-MenuItem-${menuItem.title}`}>
              {menuItem.type === 'sub' ? (
                <a
                  href="javascript"
                  className={`sidebar-link sidebar-title ${menuItem.active && 'active'}`}
                  onClick={event => {
                    event.preventDefault();
                    setNavActive(menu, menuItem);
                    activeClass(menuItem.active);
                  }}
                >
                  <SvgIcon className="stroke-icon" iconId={`stroke-${menuItem.icon}`} />
                  <SvgIcon className="fill-icon" iconId={`fill-${menuItem.icon}`} />
                  <span>{t(menuItem.title)}</span>
                  {menuItem.badge ? (
                    <label className={menuItem.badge}>{menuItem.badgetxt}</label>
                  ) : (
                    ''
                  )}
                  <div className="according-menu">
                    {menuItem.active ? (
                      <i className="fa fa-angle-down"></i>
                    ) : (
                      <i className="fa fa-angle-right"></i>
                    )}
                  </div>
                </a>
              ) : (
                ''
              )}

              {menuItem.type === 'link' && menuItem.path ? (
                <Link
                  to={menuItem.path}
                  className={`sidebar-link sidebar-title link-nav ${menuItem.active ? 'active' : ''}`}
                  onClick={event => {
                    event.preventDefault();
                    setNavActive(menu, menuItem);
                    activeClass(menuItem.active);
                  }}
                >
                  <SvgIcon className="stroke-icon" iconId={`stroke-${menuItem.icon}`} />
                  <SvgIcon className="fill-icon" iconId={`fill-${menuItem.icon}`} />
                  <span>{t(menuItem.title)}</span>
                  {menuItem.badge ? (
                    <label className={menuItem.badge}>{menuItem.badgetxt}</label>
                  ) : (
                    ''
                  )}
                </Link>
              ) : (
                ''
              )}

              {menuItem.children ? (
                <ul
                  className="sidebar-submenu"
                  style={
                    layout1 !== 'compact-sidebar compact-small'
                      ? menuItem?.active
                        ? sidebartoogle
                          ? { opacity: 1, transition: 'opacity 500ms ease-in' }
                          : { display: 'block' }
                        : { display: 'none' }
                      : { display: 'none' }
                  }
                >
                  {menuItem.children.map((childrenItem, childIndex) => (
                    <li key={`${childIndex}-child-${childrenItem.title}`}>
                      {childrenItem.type === 'sub' ? (
                        <a
                          href="javascript"
                          className={childrenItem.active ? 'active' : ''}
                          // className={`${childrenItem.active ? 'active' : ''}`}
                          onClick={
                            () /* event */ => {
                              // event.preventDefault();
                              // toggletNavActive(childrenItem);
                            }
                          }
                        >
                          {t(childrenItem.title)}
                          <span className="sub-arrow">
                            <i className="fa fa-chevron-right"></i>
                          </span>
                          <div className="according-menu">
                            {childrenItem.active ? (
                              <i className="fa fa-angle-down"></i>
                            ) : (
                              <i className="fa fa-angle-right"></i>
                            )}
                          </div>
                        </a>
                      ) : (
                        ''
                      )}

                      {childrenItem.type === 'link' ? (
                        <Link
                          to={`${childrenItem.path}`}
                          className={`${CurrentPath.includes(childrenItem?.title?.toLowerCase()) ? 'active' : ''}`}
                          // className={`${childrenItem.active ? 'active' : ''}`} bonusui
                          // onClick={() => toggletNavActive(childrenItem)}
                        >
                          {t(childrenItem.title)}
                        </Link>
                      ) : (
                        ''
                      )}

                      {childrenItem.children ? (
                        <ul
                          className="nav-sub-childmenu submenu-content"
                          style={childrenItem.active ? { display: 'block' } : { display: 'none' }}
                        >
                          {childrenItem.children.map((childrenSubItem, key) => (
                            <li key={key}>
                              {childrenSubItem.type === 'link' ? (
                                <Link
                                  to={`${childrenSubItem.path}/${layoutId}`}
                                  className={`${CurrentPath.includes(childrenSubItem?.title?.toLowerCase()) ? 'active' : ''}`}
                                  // className={`${childrenSubItem.active ? 'active' : ''}`}
                                  // onClick={() => toggletNavActive(childrenSubItem)}
                                >
                                  {t(childrenSubItem.title)}
                                </Link>
                              ) : (
                                ''
                              )}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        ''
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                ''
              )}
            </li>
          ))}
        </Fragment>
      )}
    </>
  );
};

export default SidebarMenuItems;
